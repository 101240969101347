<template>
  <section>
    <div class="d-flex flex-column align-start mb-8">
      <h1 class="black--text text-h5 font-weight-medium">Vagas</h1>
      <h2 class="text-subtitle-1">Oportunidades relacionadas ao seu perfil.</h2>
    </div>
    <RenderGenericCards />
  </section>
</template>

<script>
import RenderGenericCards from "@/modules/res/components/RenderGenericCards.vue";
export default {
  name: "VagasTab",
  components: {
    RenderGenericCards,
  },
  created() {
    this.loadingTable = true;

    // Buscar pelos dados das vagas candidatadas

    this.loadingTable = false;
  },
  methods: {},
};
</script>
